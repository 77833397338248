.browser-previewer-wrapper {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  background: rgba(0,0,0,0.6);
  z-index: 1000;

  .browser-previewer {
    height: 100%;
    background: white;

    display: flex;
    flex-direction: column;
  }


  .title-bar {
    height: 40px;
    border-bottom: 1px solid #eee;

    display: flex;
    flex-direction: row;

    .left-part {
      width: 40px;
    }

    .middle-part {
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      flex: 1;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .right-part {
      width: 40px;
      text-align: center;
      line-height: 40px;
    }
  }

  .frame-area {
    flex: 1;

    iframe {
      border: 0;
    }
  }
}

@primary-color: #215891;