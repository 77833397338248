.widget-rate-panel {
  margin-right: 5px;

  .infinite {

  }

  .no-data {
  }
}

@primary-color: #215891;