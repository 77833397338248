.widget-share-dialog-panel {
  margin-top: 20px;

  .share-block {

    .share-icon {
      width: 30px;
      height: 30px;
    }

    .name {
      font-size: 18px;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

.share-modal {
  max-width: 1000px;
}

@primary-color: #215891;