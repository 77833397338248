.widget-share-modal {

  .share-block {

    .share-icon {
      width: 30px;
      height: 30px;
    }

    .name {
      font-size: 18px;
      margin-left: 10px;
      line-height: 30px;
    }

  }

}

.share-modal {
  max-width: 1000px;
}

@primary-color: #215891;