.castle-widget-info-cell {
  word-break: break-all;
  margin-bottom: 10px;

  .info-cell-name {
    color: #99a9bf;
    font-size: 14px;
    font-weight: bold;
  }

  .info-cell-content {
    font-size: 15px;
  }

}

@primary-color: #215891;