.upload-matter-panel {

  .huge-block {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #eeeeee;
    margin-bottom: 10px;

    .progress {
      margin-bottom: 3px;
      padding-right: 15px;
    }

    .media {
      display: flex;
      justify-content: space-between;

      .media-body {
        cursor: pointer;
        color: #555;
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

}

@primary-color: #215891;