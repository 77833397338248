.widget-image-previewer{
  .PhotoView-Slider__toolbarIcon {
    display: none;
  }

  .toolbar-icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
@primary-color: #215891;