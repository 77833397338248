@import "../../assets/css/global/variables";

.page-space-list {
  min-height: 100%;
  .space-item {
    width: 100%;
    cursor: pointer;
    &-name-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    &-name {
      flex-grow: 1;
      font-size: 16px;
      position: relative;
      height: 25px;
    }
    &-icons {
      flex-shrink: 0;
    }
    .btn {
      &-member, &-edit {
        color: @primary-color;
      }
      &-del {
        color: @brand-danger
      }
    }
  }
  .space-pagination {
    margin-top: 10px;
  }
}

@primary-color: #215891;