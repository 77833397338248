.matter-image {
  .composite-box {
    display: flex;

    .content {
      display: flex;
      flex-grow: 1;
      position: relative;

      .text {
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }

    .ant-upload {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    .border-short {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .handle {
      flex-shrink: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

@primary-color: #215891;