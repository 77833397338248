@import "../../../assets/css/global/variables";

@base-height: 48px;
@inner-cell-height: 36px;

.basic-span {
  display: inline-block;
  vertical-align: middle;
  line-height: @base-height;
  margin-left: 10px;
}

.widget-matter-panel {

  border-top: 1px solid #eee;
  background-color: white;

  .cell {
    display: inline-block;
    vertical-align: middle;
    line-height: @base-height;
    margin-left: 10px;

    &-hot {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -10px;
        bottom: 0;
        left: -10px;
        content: '';
      }
    }
  }

  .btn-action {
    font-size: 15px;
  }

  .media {
    > .pull-left {
      padding-right: 1px;
    }
  }

  .matter-icon {
    width: 24px;
  }

  .middle-part {

    height: @base-height;
    overflow: hidden;

    .matter-name-edit {
      .basic-span;

      width: 90%;

      input {
        width: 100%;
        height: 26px;
        display: inline-block;
        padding: 6px;
      }
    }

    .matter-name {
      .basic-span;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .icon {
        font-size: 11px;
        margin-left: 3px;
      }
    }
  }

  .right-part {

    .matter-operation {
      .basic-span;
      display: none;

      i {
        font-size: 16px;
        margin-right: 5px;

        &:hover {

        }
      }
    }

    .matter-size {
      .basic-span;
      display: inline-block;
      width: 80px;
      text-align: left;
      margin-left: 20px;
    }

    .matter-date {
      .basic-span;
    }

  }

  .more-btn {
    display: inline-block;
    vertical-align: middle;
    line-height: @base-height;
    padding: 0 15px;
  }

  &:hover {
    background-color: aliceblue;
    cursor: pointer;

    .matter-operation {
      display: inline-block;
    }
  }

  .more-panel {
    border-top: 1px solid #eee;
    padding-left: 32px;

    .text {
      margin-left: 5px;
    }

    .matter-size {
      margin-left: 15px;
    }

    .cell-btn {
      border-top: 1px solid #eee;
      line-height: @inner-cell-height;
      vertical-align: middle;

      &:first-child {
        border-top: none;
      }
    }
  }
}

.anticon {
  color: inherit;
}

@primary-color: #215891;