.preview-engine-cell {
  position: relative;
  border: 1px solid #eee;
  padding: 10px 10px 0;
  margin-bottom: 10px;

  .tip-box {
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@primary-color: #215891;