.matter-list {
  min-height: calc(100% + 20px);
  margin: -10px;
  padding: 10px;
  position: relative;
  .obscure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .ant-upload {
    display: inline-block;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
  }
  .matter-pagination {
    margin-top: 10px;
  }
}

@primary-color: #215891;